<template>
  <div class="h-screen flex items-center justify-center overflow-hidden">
    <div class="relative flex w-full h-full overflow-hidden" style="max-width: 500px; max-height: 500px">
      <div id="canvas" class="items-middle">
        <!-- base image -->
        <img :src="`https://islandboys.s3.amazonaws.com/500/${item_id}.png`" alt="" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-contain no-user-action" style="z-index: 0" />
        <!-- customs overlay -->
        <img v-if="selected_customs" :src="customs_image" alt="" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-contain no-user-action" style="z-index: 1" />
      </div>
      <!-- version toggole -->
      <!-- <div class="absolute top-2 right-2" style="z-index: 100">
        <div class="relative">
          <button @click="_toggleVersionDropDown" class="flex flex-col items-center rounded px-2 bg-gray-700 rounded">
            <p class="font-number font-bold text-sm text-white">
              {{ selected_version }}
            </p>
          </button>
          <div v-if="versionDropDown" class="absolute left-0 right-0 mt-1 shadow-lg bg-gray-700 rounded z-20">
            <div class="flex flex-col items-center justify-center gap-y-1 py-2 px-1">
              <button @click="_updateVersionSelection(index)" v-for="(version, index) in versions" :key="index" class="relative w-full h-8 group">
                <div class="flex flex-row items-center justify-center whitespace-nowrap" :class="index == selected_version_index ? 'text-rug-green' : 'text-white'">
                  <div class="font-number font-bold text-sm text-center align-middle text-neutral-content">
                    {{ version.name }}
                  </div>
                </div>

                <div v-if="version.description" class="absolute top-0 right-12 bg-gray-700 rounded p-2 mr-1 text-xs text-primary hidden group-hover:block">
                  {{ version.description }}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div> -->
      <!-- customs toggle -->
      <div v-if="is_customs_available && overlay_items" class="absolute bottom-2 left-2" style="z-index: 100">
        <div class="relative flex items-end justify-start gap-x-1">
          <button @click="_toggleCustomsDropDown" class="flex flex-col items-center rounded px-2 bg-gray-700 rounded">
            <p class="font-number font-bold text-sm text-white capitalize">
              {{ selected_customs ? selected_customs : 'none' }}
            </p>
          </button>
        </div>
      </div>
      <div class="absolute top-10 left-2 bottom-10 right-2 flex items-end justify-center">
        <div class="flex items-center justify-center">
          <div v-if="customsDropDown" class="rounded z-20">
            <div class="flex flex-row flex-wrap items-left justify-center gap-x-1 gap-y-1 py-2 px-1 overflow-y-scroll overflow-x-hidden bg-black bg-opacity-60">
              <button @click="_updateCustomsSelection(index)" v-for="(overlay, index) in overlay_items" :key="index" class="hover:bg-base-300">
                <button class="flex flex-col items-center justify-center shadow-lg bg-gray-700 rounded px-1" :class="index == selected_customs_index ? 'text-rug-green' : 'text-white'">
                  <div class="font-number font-bold text-sm text-center align-middle capitalize">
                    {{ overlay }}
                  </div>
                </button>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- save image -->
      <div class="absolute bottom-2 right-2" style="z-index: 100">
        <button @click="copy_image_download_url" class="bg-gray-700 rounded pb-1 px-1">
          <img src="@/assets/icons/download-icon.svg" alt="" class="object-contain h-6" />
        </button>
      </div>
      <div class="absolute bottom-2 right-12 left-12 flex items-end justify-end" style="z-index: 100">
        <div v-if="show_download_message" class="font-body font-medium text-right text-xs bg-gray-700 text-primary rounded px-3 py-2">{{ download_message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      item_id: this.$route.params.id,
      // versions
      // versionDropDown: false,
      // versions: versions,
      // selected_version_index: null,
      // customs
      customsDropDown: false,
      overlay_items: null,
      selected_customs_index: null,
      customs_unavailable: [],
      customs_blacklist: [],
      customs_help_url: 'https://islandboys.s3.amazonaws.com/custom_bot/help.txt',
      // download message
      download_message: null,
      show_download_message: false,
    }
  },
  computed: {
    selected_customs() {
      return this.selected_customs_index || this.selected_customs_index == 0 ? this.overlay_items[this.selected_customs_index] : null
    },
    is_customs_available() {
      return !this.customs_unavailable.includes(this.item_id)
    },
    customs_image() {
      return `https://islandboys.s3.amazonaws.com/custom_bot/${this.overlay_items[this.selected_customs_index]}/1000/all.png`
    },
    download_image_url() {
      if (this.customs_blacklist.includes(this.selected_customs)) {
        // let fur_color = this.item_metadata.fur.toLowerCase()
        // return window.location.origin + `/download/${this.item_id}?version=${this.selected_version_value}${this.selected_customs ? `&custom=${this.selected_customs}&variant=${fur_color}` : ''}`
        return window.location.origin + `/download/${this.item_id}${this.selected_customs ? `?custom=${this.selected_customs}&variant=all` : ''}`
      } else {
        return window.location.origin + `/download/${this.item_id}${this.selected_customs ? `?custom=${this.selected_customs}&variant=all` : ''}`
      }
    },
  },
  async created() {},
  async mounted() {
    await this.get_customs_data()
  },
  methods: {
    // _toggleVersionDropDown() {
    //   this.customsDropDown = false
    //   this.versionDropDown = !this.versionDropDown
    // },
    _toggleCustomsDropDown() {
      this.versionDropDown = false
      this.customsDropDown = !this.customsDropDown
    },
    // _updateVersionSelection(index) {
    //   this.selected_version_index = index
    //   this._toggleVersionDropDown()
    // },
    _updateCustomsSelection(index) {
      if (this.selected_customs_index == index) {
        // remove customs if same index is selected
        this.selected_customs_index = null
      } else {
        // update selection
        this.selected_customs_index = index
      }
      this._toggleCustomsDropDown()
    },
    async get_customs_data() {
      await axios
        .get(this.customs_help_url)
        .then((response) => {
          let help_txt = response.data
          this.overlay_items = help_txt.split('\r\n').filter((item) => {
            return item != '' && !this.customs_blacklist.includes(item)
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    _toggle_show_download_message(bool, message) {
      this.download_message = message
      this.show_download_message = bool
    },
    copy_image_download_url() {
      var image_download_el = document.createElement('textarea')
      image_download_el.value = this.download_image_url
      image_download_el.style.top = '0'
      image_download_el.style.left = '0'
      image_download_el.style.position = 'fixed'
      document.body.appendChild(image_download_el)
      image_download_el.focus()
      image_download_el.select()
      try {
        let success = document.execCommand('copy')
        this._toggle_show_download_message(true, 'Image link copied!')
        setTimeout(() => {
          this._toggle_show_download_message(false, null)
        }, 1500)
      } catch (error) {
        this._toggle_show_download_message(true, 'Could not copy image url')
        setTimeout(() => {
          this._toggle_show_download_message(false, null)
        }, 1500)
        console.log(error)
      }
      document.body.removeChild(image_download_el)
    },
    async _save_image() {
      console.log('saving image...')
    },
  },
}
</script>

<style scoped>
.no-user-action {
  user-select: none;
  pointer-events: none;
}
/* hide scrollbar but allow scrolling */
element {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

element::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
  background: transparent; /* make scrollbar transparent */
}
</style>
