<template>
  <div class="h-screen flex items-center justify-center overflow-hidden">
    <div class="relative flex w-full overflow-hidden" :style="`background-color: ${bg_color};`" style="max-width: 500px; max-height: 500px">
      <div id="canvas" class="items-middle">
        <!-- base image -->
        <img crossorigin="anonymous" @load="_main_loaded" :src="main_image" alt="" class="w-full object-contain" style="z-index: 0" />
        <!-- customs overlay -->
        <img crossorigin="anonymous" v-if="selected_custom" @load="_customs_loaded" :src="customs_image" alt="" class="absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-contain" style="z-index: 1" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
// import versions from '@/assets/data/versions.json'
// import collab_versions from '@/assets/data/collab_versions.json'
// import background_reference from '@/assets/data/background_color.json'

import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver'

export default {
  name: 'Download',
  components: {},
  data() {
    return {
      item_id: this.$route.params.id,
      // selected_version: this.$route.query.version,
      selected_custom: this.$route.query.custom,
      custom_variant: this.$route.query.variant,
      is_downloaded: false,
      // state flags
      main_loaded: false,
      customs_loaded: false,
      // adoptive background color
      // metadata_url: `https://islandboys.s3.amazonaws.com/metadata/iframe/${this.$route.params.id}.json`,
      item_metadata: null,
      bg_color: '#000000',
      text_color: '#ffffff',
      // customs
      customs_help_url: 'https://islandboys.s3.amazonaws.com/custom_bot/help.txt',
      customs_unavailable: [],
      customs_blacklist: [],
    }
  },
  computed: {
    selected_customs() {
      return this.selected_customs_index || this.selected_customs_index == 0 ? this.overlay_items[this.selected_customs_index] : null
    },
    is_customs_available() {
      return !this.customs_unavailable.includes(this.item_id)
    },
    main_image() {
      return `https://islandboys.s3.amazonaws.com/500/${this.item_id}.png`
    },
    customs_image() {
      return `https://islandboys.s3.amazonaws.com/custom_bot/${this.selected_custom}/1000/${this.custom_variant}.png`
    },
    // version_name() {
    //   if (!collab_versions.item_ids.includes(parseInt(this.item_id))) {
    //     // default version names
    //     let version_names = versions.flatMap((item) => {
    //       if (item.value == this.selected_version) {
    //         console.log(item.name.toLowerCase())
    //         return item.name.toLowerCase()
    //       } else {
    //         return []
    //       }
    //     })
    //     console.log(version_names)
    //     return version_names[0]
    //   } else {
    //     // collabs
    //     let version_names = collab_versions.versions.flatMap((item) => {
    //       if (item[this.item_id].value == this.selected_version) {
    //         return item.name
    //       } else {
    //         return []
    //       }
    //     })
    //     console.log(version_names)
    //     return version_names[0]
    //   }
    // }
  },
  async created() {
  },
  async mounted() {
  },
  methods: {
    _main_loaded() {
      this.main_loaded = true
      this._attempt_download()
    },
    _customs_loaded() {
      this.customs_loaded = true
      this._attempt_download()
    },
    _attempt_download() {
      if (this.is_downloaded) {
        // skip if already started downloading
        return
      }
      if (this.custom_variant && !this.customs_loaded) {
        // wait until customs image is loaded
        return
      }
      this.is_downloaded = true
      this._save_image()
    },
    async get_customs_data() {
      await axios
        .get(this.customs_help_url)
        .then((response) => {
          let help_txt = response.data
          this.overlay_items = help_txt.split('\r\n').filter((item) => {
            return item != ''
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    hexToR(h) {
      return parseInt(this.cutHex(h).substring(0, 2), 16)
    },
    hexToG(h) {
      return parseInt(this.cutHex(h).substring(2, 4), 16)
    },
    hexToB(h) {
      return parseInt(this.cutHex(h).substring(4, 6), 16)
    },
    cutHex(h) {
      return h.charAt(0) == '#' ? h.substring(1, 7) : h
    },
    async _save_image() {
      console.log('saving image...')
      let image_name = `islandboys_${this.item_id}${this.selected_custom ? '_' + this.selected_custom : ''}.png`
      let imageContainer = document.getElementById('canvas')
      let appending_child = document.createElement('img');
      appending_child.style.position = 'absolute'
      appending_child.style.top = '50%'
      appending_child.style.left = '50%'
      appending_child.style.transform = 'translate(-50%, -50%)'
      appending_child.style.overflow = 'hidden'
      appending_child.style.objectFit = 'contain'
      appending_child.style.zIndex = 101
      imageContainer.style.width = '500px'
      imageContainer.style.height = 'auto'
      html2canvas(imageContainer, { useCORS: true }).then(function (canvas) {
        appending_child.src = canvas.toDataURL()
        imageContainer.appendChild(appending_child)
        canvas.toBlob(function (blob) {
          console.log(blob)
          saveAs(blob, image_name)
        })
      })
    },
  },
}
</script>

<style scoped>
/* hide scrollbar but allow scrolling */
element {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

element::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
  background: transparent; /* make scrollbar transparent */
}
</style>
